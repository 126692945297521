import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  useMediaQuery,
  Button,
} from '@mui/material';
import { useTeamQuery } from '../../app/queries/team.query';
import TeamCard from '../Cards/TeamCard';
import styles from './styles';

const PATH = 'team';
const TEAMS = [
  {
    value: 'business',
    title: 'Бизнес-команда',
  },
  {
    value: 'msk',
    title: 'Москва',
  },
  {
    value: 'spb',
    title: 'Санкт-Петербург',
  },
  {
    value: 'online',
    title: 'Онлайн',
  },
];

function Team({ team, message }) {
  const [filteredTeam, setFilteredTeam] = useState(team);
  const [filter, setFilter] = useState(TEAMS[0].value);
  const [isShortList, setIsShortList] = useState(false);
  const [isSmallTeam, setIsSmallTeam] = useState(false);
  const { teamImgs } = useTeamQuery();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isRemainder = filteredTeam.length % 4 === 1;

  useEffect(() => {
    const filteredList = filterList(filter);
    const fullList = isShortList ? filteredList.slice(0, 5) : filteredList;
    fullList.push(message);
    setFilteredTeam(fullList);
    setIsSmallTeam(filteredList.length <= 6);
  }, [filter, isShortList]);

  useEffect(() => {
    setIsShortList(isSm);
  }, [isSm, filter]);

  const changeTeam = (evt) => {
    setFilter(evt.target.value);
  };

  const filterList = (currentFilter) =>
    team.filter((person) => person.isInfo || person.team === currentFilter);

  return (
    <Box sx={styles.container}>
      {/* <Grid container>
        <Grid item xs={12} sm={6} md={3} sx={styles.selectWrapper}>
          <FormControl variant="standard" sx={styles.select}>
            <Select
              labelId="team"
              id="team-select"
              value={filter}
              label=""
              onChange={changeTeam}
            >
              {TEAMS.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  name="team"
                  className="selectItem"
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid> */}
      <Grid container>
        {filteredTeam.map((card, index) => (
          <Grid
            item
            key={card.name || index}
            xs={12}
            sm={6}
            md={3}
            sx={styles.cardWrapper}
            className={`${card.isInfo && 'isInfoWrapper'} ${
              card.isLengthDependent && 'isRemainderWrapper'
            }`}
          >
            <Box
              sx={styles.card}
              className={`${card.isInfo && 'isInfo'} ${
                card.isLengthDependent && isRemainder && 'isRemainder'
              }`}
            >
              {card.isInfo ? (
                <>
                  <Box>
                    {!!card.description && (
                      <Typography variant="mobileP2" sx={styles.description}>
                        {card.description}
                      </Typography>
                    )}
                    {!!card.statistic && (
                      <>
                        <Typography
                          value="desktopH1"
                          component="p"
                          sx={styles.statisticValue}
                        >
                          {card.statistic.value}
                        </Typography>
                        <Typography variant="desktopP2" color="kit.text.main">
                          {card.statistic.title}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {!!card.bottomDescription && (
                    <Typography
                      variant="mobileP2"
                      sx={styles.bottomDescription}
                    >
                      {card.bottomDescription}
                    </Typography>
                  )}
                </>
              ) : (
                <TeamCard
                  {...card}
                  path={card.image ? teamImgs[`${PATH}/${card.image}`] : null}
                />
              )}
            </Box>
          </Grid>
        ))}
        {isSmallTeam ||
          (isShortList && (
            <Grid xs={12} sx={styles.buttonBlock}>
              <Button onClick={() => setIsShortList(false)} variant="outlined">
                Посмотреть всю команду
              </Button>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default Team;
