const data = {
  seo: {
    title: 'О школе программирования Эльбрус Буткемп - всё о формате буткемпа',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Эльбрус Буткемп - это команда профессионалов, которые помогают людям освоить программирование и начать карьеру в IT-индустрии. Узнайте больше о нашей истории и ценностях.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'О школе программирования Эльбрус Буткемп - всё о формате буткемпа',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Эльбрус Буткемп - это команда профессионалов, которые помогают людям освоить программирование и начать карьеру в IT-индустрии. Узнайте больше о нашей истории и ценностях.',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/8bffaf40ed5f99b92f2597cbff1733d5/f85ce/slide-8.jpg',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/8bffaf40ed5f99b92f2597cbff1733d5/f85ce/slide-8.jpg',
      },
    ],
  },
  content: [
    {
      mainTitle: 'О школе',
      bg: 'true',
      video: {
        src: 'https://www.youtube.com/embed/Kh_tvgwOtog',
        description: '1 час 12 минут',
      },
      items: [
        {
          description:
            'Эльбрус Буткемп появился в 2018 году благодаря Георгию Бабаяну. Его дедушка был программистом и участвовал в создании микропроцессора Эльбрус — отсюда и название школы. Сам Георгий в 2016 году прошел Dev Bootcamp в Сан-Франциско, один из самых старых буткемпов в Америке. Тогда о подобном формате в России еще не слышали, а в Америке он набирал популярность в сфере IT.',
        },
        {
          description:
            'Буткемп давал возможность обучиться за 3-4 месяца тому, на что раньше требовались годы, и, следовательно, освоить новую профессию и найти работу. По возвращении Георгий решил внедрить подобный формат обучения и в России по двум направлениям — JavaScript и Data Science. Первый кампус Эльбрус Буткемп появился в Москве, затем открылся филиал в Санкт-Петербурге, а теперь обучаться можно и онлайн.',
        },
      ],
    },
    {
      items: [
        {
          image: 'team',
          isOnTheLeft: true,
          message: 'Научим кодить даже твою бабушку.',
          messageStyles: {
            top: {
              xs: '24%',
              sm: '12%',
            },
            left: {
              xs: '52%',
              sm: '42%',
            },
            right: 'unset',
            '.text': {
              width: '109px',
            },
            display: {
              xs: 'block',
              md: 'none',
            },
          },
          description:
            'В школе преподают практикующие программисты и разработчики — у каждой группы есть свои менторы и ассистенты, помогающие с любыми вопросами. Одна из ключевых ценностей Эльбрус Буткемп — growth mindset: преподаватели и сотрудники буткемпа постоянно учатся и развиваются, чтобы делиться со студентами самыми актуальными знаниями.',
        },
        {
          isMobileHidden: 'true',
          isOnTheLeftMobile: true,
          image: 'cheer',
          message: 'У тебя получится, я тоже начинал с 0.',
          messageStyles: {
            top: {
              xs: '3%',
            },
            right: {
              xs: 'unset',
              lg: '28%',
            },
            left: {
              xs: '63%',
              lg: 'unset',
            },
            '.text': {
              width: '123px',
            },
          },
        },
      ],
    },
    {
      mainPhoto: {
        image: 'birthday',
        isOnTheLeft: true,
        icon: 'purple-heart',
        message:
          'Это мы вместе ДР Эльбрус Буткемп отмечаем! Комьюнити — наша сила',
        delay: 1200,
        messageStyles: {
          top: 'unset',
          bottom: {
            xs: '39%',
            sm: '30%',
          },
          left: '50%',
          right: 'unset',
          '.text': {
            width: {
              xs: '142px',
              md: '170px',
            },
          },
        },
      },
      statistic: [
        {
          id: 1,
          description: 'студентов выпущено за 4 года',
          value: '1017',
        },
        {
          id: 2,
          description: 'студентов находят работу за 3 месяца',
          value: '93%',
        },
        {
          id: 5,
          link: {
            title: 'Рейтинг Яндекса',
            url: 'https://yandex.ru/search/?lr=213&text=elbrus+bootcamp',
          },
          value: '5/5',
        },
      ],
      items: [
        {
          description:
            'Студенты выбирают Эльбрус Буткемп за предсказуемый результат, ведь главная цель школы — трудоустройство выпускников. Так к выпуску у каждого студента есть три проекта для портфолио: индивидуальный, групповой и проект от реального заказчика, а по окончании курса всех ждет серия групповых и индивидуальных сессий с карьерными коучами. Благодаря такому подходу 86% выпускников находят работу в течение трех месяцев сразу после курсов.',
        },
        {
          description:
            'Эльбрус Буткемп — это сильное поддерживающее коммьюнити, люди в котором продолжают общаться и после выпуска.',
        },
      ],
    },
    {
      type: 'team',
      title: 'Команда',
      message: {
        isInfo: true,
        isLengthDependent: true,
        bottomDescription:
          'Мы всегда рады тем, кто разделяет нашу миссию и ценности.',
      },
      items: [
        // ─── Верхний Ряд Фото ────────────────────────
        {
          name: 'Георгий Бабаян',
          position: 'Генеральный директор',
          image: 'babayan',
          team: 'business',
        },
        {
          name: 'Юрий Плисковский',
          position: 'Директор по образованию',
          image: 'pliskovskiy',
          team: 'business',
        },
        {
          name: 'Илона Гольман',
          position: 'Директор по образованию',
          image: 'golman',
          team: 'business',
        },
        {
          name: 'Мария Васильева',
          position: 'Руководитель приемной комиссии',
          image: 'vasilyeva',
          team: 'business',
        },
        {
          name: 'Надежда Крутикова',
          position: 'Руководитель карьерного центра',
          image: 'krutikova',
          team: 'business',
        },
        {
          name: 'Полина Береснева',
          position: 'Коммерческий директор',
          image: 'beresneva',
          team: 'business',
        },
        // {
        //   name: 'Александр Князев',
        //   position: 'Тимлид JS преподавателей',
        //   image: 'knyazev',
        //   team: 'msk',
        // },
        // {
        //   name: 'Антон Панферов',
        //   position: 'Преподаватель JS',
        //   image: 'panferov',
        //   team: 'msk',
        // },
        // {
        //   name: 'Юлия Павлова',
        //   position: 'Преподаватель JS',
        //   image: 'pavlova',
        //   team: 'msk',
        // },
        // {
        //   name: 'Елена Лушникова',
        //   position: 'Преподаватель JS',
        //   image: 'lushnikova',
        //   team: 'spb',
        // },
        // {
        //   name: 'Анатолий Башкатов',
        //   position: 'Преподаватель JS',
        //   image: 'bashkatov',
        //   team: 'spb',
        // },
        // {
        //   name: 'Артем Оганесян',
        //   position: 'Тимлид JS преподавателей',
        //   image: 'oganesyan',
        //   team: 'online',
        // },
        // {
        //   name: 'Роман Парамзин',
        //   position: 'Преподаватель JS',
        //   image: 'paramzin',
        //   team: 'online',
        // },
        // {
        //   name: 'Денис Образцов',
        //   position: 'Преподаватель JS',
        //   image: 'obrazcov',
        //   team: 'online',
        // },
        // {
        //   isInfo: true,
        //   description:
        //     'В команде работают люди из разных стран и городов. Офисы находятся в Москве и Санкт-Петербурге.',
        //   statistic: {
        //     value: '56',
        //     title: 'человек',
        //   },
        // },
        // // ─────────────────────────────────────────────
        // //
        // // ─── Остальные Фото ──────────────────────────
        // {
        //   name: 'Дина Долинова',
        //   position: 'Продакт-менеджер',
        //   image: 'dolinova',
        //   team: 'business',
        // },
        // {
        //   name: 'Егор Васкевич',
        //   position: 'Куратор',
        //   image: 'vaskevich',
        //   team: 'business',
        // },
        // {
        //   name: 'Елизавета Кругликова',
        //   position: 'Куратор',
        //   image: 'kruglikova',
        //   team: 'business',
        // },
        // {
        //   name: 'Юлия Рогачева',
        //   position: 'Куратор',
        //   image: 'rogacheva',
        //   team: 'business',
        // },
        // {
        //   name: 'Евгения Корж',
        //   position: 'Куратор',
        //   image: 'korsh',
        //   team: 'business',
        // },
        // {
        //   name: 'Ирма Евженко',
        //   position: 'Куратор',
        //   image: 'evshenko',
        //   team: 'business',
        // },
        // {
        //   name: 'Алина Петрова',
        //   position: 'Куратор',
        //   image: 'petrova',
        //   team: 'business',
        // },
        // {
        //   name: 'Татьяна Алфимова',
        //   position: 'Руководитель отдела контент маркетинга',
        //   image: 'alfimova',
        //   team: 'business',
        // },
        // {
        //   name: 'Ионна Авагян',
        //   position: 'Коммьюнити менеджер',
        //   image: 'avagyan',
        //   team: 'business',
        // },
        // {
        //   name: 'Дарина Афанасьева',
        //   position: 'Менеджер Тикток и YouTube',
        //   image: 'afanasyeva',
        //   team: 'business',
        // },
        // {
        //   name: 'Галина Викулина',
        //   position: 'Копирайтер',
        //   image: 'vikulina',
        //   team: 'business',
        // },
        // {
        //   name: 'Ксения Гуляева',
        //   position: 'SMM-менеджер',
        //   image: 'gulyaeva',
        //   team: 'business',
        // },
        // {
        //   name: 'Катрин Алимова',
        //   position: 'Тимлид разработки',
        //   image: 'alimova',
        //   team: 'business',
        // },
        // // {
        // //   name: 'Павел Росихин',
        // //   position: 'Продакт-менеджер',
        // //   image: 'rosichin',
        // //   team: 'business',
        // // },
        // {
        //   name: 'Владимир Кутузов',
        //   position: 'QA-инженер',
        //   image: 'kutuzov',
        //   team: 'business',
        // },
        // {
        //   name: 'Алексей Учкин',
        //   position: 'Разработчик',
        //   image: 'uchkin',
        //   team: 'business',
        // },
        // {
        //   name: 'Станислав Рогачков',
        //   position: 'Graphic & UX/UI дизайн',
        //   image: 'rogachkov',
        //   team: 'business',
        // },
        // {
        //   name: 'Екатерина Ничипорук',
        //   position: 'UX/UI дизайн',
        //   image: 'nichiporuk',
        //   team: 'business',
        // },
        // {
        //   name: 'Диляра Никитина',
        //   position: 'Главный бухгалтер',
        //   image: 'nikitina',
        //   team: 'business',
        // },
        // {
        //   name: 'Адам Балкоев',
        //   position: 'Преподаватель JS',
        //   image: 'balkoev',
        //   team: 'msk',
        // },
        // {
        //   name: 'Артем Бахарев',
        //   position: 'Преподаватель JS',
        //   image: 'bacharev',
        //   team: 'spb',
        // },
        // {
        //   name: 'Анна Макарова',
        //   position: 'Преподаватель JS',
        //   image: 'makarova',
        //   team: 'spb',
        // },
        // {
        //   name: 'Олег Доденко',
        //   position: 'Преподаватель JS',
        //   image: 'dodenko',
        //   team: 'online',
        // },
        // {
        //   name: 'Мовсар',
        //   position: 'Преподаватель JS',
        //   image: 'movsar',
        //   team: 'online',
        // },
        // {
        //   name: 'Сергей Дочкин',
        //   position: 'Преподаватель JS',
        //   image: 'dochkin',
        //   team: 'online',
        // },
        // {
        //   name: 'Павел Думин',
        //   position: 'Тимлид DS преподавателей',
        //   image: 'dumin',
        //   team: 'online',
        // },
        // {
        //   name: 'Улюмджи Улюмджиев',
        //   position: 'Преподаватель DS',
        //   image: 'ulumdzhiev',
        //   team: 'online',
        // },
        // {
        //   name: 'Светлана Корейша',
        //   position: 'Куратор кампуса',
        //   image: 'koreysha',
        //   team: 'online',
        // },
        // {
        //   name: 'Виктория Васькова',
        //   position: 'Куратор кампуса',
        //   image: 'vaskova',
        //   team: 'online',
        // },
        // {
        //   name: 'Александра Куликова',
        //   position: 'Карьерный коуч',
        //   image: 'kulikova',
        //   team: 'online',
        // },
        // {
        //   name: 'Мария Елисеева',
        //   position: 'Карьерный коуч',
        //   image: 'eliseeva',
        //   team: 'online',
        // },
      ],
    },
    {
      title: 'Критерии зачисления',
      type: 'qualities',
      items: [
        {
          textStyles: {
            height: {
              lg: '160px',
              sm: '100%',
            },
          },
          description:
            'Чтобы попасть в Эльбрус буткемп, не нужно иметь опыт в программировании или математический склад ума. Достаточно желания получить новую профессию и быть готовым осваивать большой объем новой информации.',
        },
        {
          isOnTheLeftMobile: true,
          description:
            'Перед поступлением будущих программистов ждет техническое интервью. Это нужно, чтобы студенты поняли, с чем им предстоит работать в ближайшие месяцы и убедились в серьезности своих намерений. Наши кураторы расскажут, как к нему подготовиться, и пришлют все необходимые материалы, которые можно и нужно использовать для подготовки.',
        },
      ],
      qualitiesTitle: 'Какие качества важны\nв будущих студентах?',
      qualitiesList: [
        {
          name: 'Мотивация',
          image: 'quality-1',
          description:
            'Вы серьезно настроены получить новую профессию и знаете, для чего вы пришли в буткемп.',
        },
        {
          name: 'Целеустремленность',
          image: 'quality-2',
          description:
            'Вы понимаете, что местами будет сложно, но готовы идти этот путь до конца, потому что видите конечную цель.',
        },
        {
          name: 'Мышление роста',
          image: 'quality-3',
          description:
            'Вы знаете, что все в ваших руках, и готовы приложить усилия, чтобы изменить свою жизнь к лучшему.',
        },
      ],
    },
    {
      type: 'slider',
      title: 'Среда обучения',
      items: [
        {
          description:
            'Эльбрус Буткемп предлагает вам комфортную и функциональную среду для обучения программированию. Наши кампусы располагаются в оживлённых районах Москвы (м. Ленинский проспект) и Санкт-Петербурга (м. Обводный канал).',
        },
        {
          description:
            'В кампусе Эльбрус Буткемп есть уютная кухня и зона отдыха. В отдельной комнате для преподавателей можно обсуждать рабочие проекты с наставником, не отвлекая от работы остальных.',
        },
      ],
      slides: [
        {
          image: 'slide-1',
        },
        {
          image: 'slide-2',
        },
        {
          image: 'slide-3',
        },
        {
          image: 'slide-4',
        },
        {
          image: 'slide-5',
        },
        {
          image: 'slide-6',
        },
        {
          image: 'slide-7',
        },
        {
          image: 'slide-8',
        },
        {
          image: 'slide-9',
        },
      ],
    },
    {
      title: 'Карьера',
      mainPhoto: {
        image: 'career',
        message: 'Чем больше откликаетесь на вакансии, тем ближе оффер мечты!',
        messageStyles: {
          top: {
            xs: '25%',
            sm: '-1%',
            md: '12%',
            lg: '13%',
          },
          right: {
            xs: 'unset',
            lg: '33%',
            xl: '76%',
          },
          left: {
            xs: '84%',
            sm: '-14%',
            lg: 'unset',
          },
          '.text': {
            width: {
              xs: '151px',
              md: '255px',
            },
          },
        },
      },
      actions: {
        first: {
          title: 'О трудоустройстве выпускников',
        },
        second: {
          title: 'Посмотреть Программы',
          link: '/#program',
        },
      },
      items: [
        {
          description:
            'Карьерные коучи Эльбрус Буткемп помогают составить сильное резюме, прокачать софт-скиллы и научиться проходить собеседования, чтобы получить оффер мечты.',
        },
        {
          description:
            'Наши выпускники работают в таких крупных компаниях, как Ozon, Финам, МТС, VK и Сбер.',
        },
      ],
    },
  ],
  questions: {
    message: 'Остались вопросы?',
    actions: {
      first: {
        title: 'О трудоустройстве выпускников',
      },
      second: {
        title: 'Посмотреть Программы',
      },
    },
    ymEvents: {
      mainProgrammsBottomClick: 'mainProgrammsBottom',
      consultationFormSubmit: 'mainConsultation',
    },
  },
};

export default data;
